require('./bootstrap');
import * as coreui from '@coreui/coreui';
window.coreui = coreui;
require('select2');
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import 'datatables.net-bs5';
import 'datatables.net-searchbuilder-bs5';
import 'datatables.net-responsive-bs5';
import 'datatables.net-datetime';
import 'datatables.net-select-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-plugins/sorting/datetime-moment';
import 'bootstrap-notify';
window.moment = require('moment');
window.SignaturePad = require('signature_pad');

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css"; // Import the CSS
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
});
